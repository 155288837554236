import React, { useState, useEffect } from "react";
import "./styles/Splash.css";
import template from "..//..//src/template.json";

export default function Splash(props) {
  let resized = false;
  let inputRef = React.createRef();
  const [delegates, setDelegates] = useState([]);
  const [error, setError] = useState(null);
  const [update, setUpdate] = useState(null);

  useEffect(() => {
    let opacity = 1;
    if (template.style.splash.opacity) opacity = template.style.splash.opacity;

    document.body.style.backgroundImage =
      "linear-gradient(rgba(0, 0, 0," +
      opacity +
      "), rgba(0, 0, 0," +
      opacity +
      ")), url('images/ui/bg.jpg')";

    async function loadDels() {
      const dels = await getDelgates();
      setDelegates(dels);
    }

    loadDels();

    window.addEventListener("resize", () => {
      setUpdate(null);
    });
  }, []);

  function renderMobile() {
    return (
      <>
        <div className={"splash"}>
          <div id={"header"}>
            <img className={"logo-image"} src={"images/ui/logo.png"} />
            <h1>{template.style.splash.title}</h1>
            <p>{template.style.splash.message}</p>
          </div>

          <div id="example">
            <img
              className={"example-image"}
              src={template.style.splash.exampleImage}
            ></img>
          </div>

          <div id={"login"}>
            {error}
            <input
              name={"codeInput"}
              placeholder={
                template.style.splash.inputPlaceholder
                  ? template.style.splash.inputPlaceholder
                  : "Enter Voucher Code"
              }
              className={"codeInput"}
              ref={inputRef}
              type={"text"}
            />
            <button id={"submitButton"} onClick={validateID}>
              Continue
            </button>
            {template.style.splash.termsText ? (
              <p id={"terms"}>{template.style.splash.termsText}</p>
            ) : null}{" "}
            <br></br>
            <a
              onClick={() =>
                window.open(template.style.splash.privacyPolicyLink)
              }
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </>
    );
  }

  function renderDesktop() {
    return (
      <>
        <div className={"splash"}>
          <div id={"login"}>
            <img className={"logo-image"} src={"images/ui/logo.png"} />
            <h1>{template.style.splash.title}</h1>
            <p>{template.style.splash.message}</p>
            {error}
            <input
              name={"codeInput"}
              placeholder={
                template.style.splash.inputPlaceholder
                  ? template.style.splash.inputPlaceholder
                  : "Enter Voucher Code"
              }
              className={"codeInput"}
              ref={inputRef}
              type={"text"}
            />
            <button id={"submitButton"} onClick={validateID}>
              Continue
            </button>
            {template.style.splash.termsText ? (
              <p id={"terms"}>{template.style.splash.termsText}</p>
            ) : null}{" "}
            <br></br>
            <a
              onClick={() =>
                window.open(template.style.splash.privacyPolicyLink)
              }
            >
              Privacy Policy
            </a>
          </div>

          <div id={"example"}>
            <img
              className={"example-image"}
              src={template.style.splash.exampleImage}
            ></img>
          </div>
        </div>
      </>
    );
  }

  function validateID() {
    // Check for dev code. We do not post designs or send email in dev mode
    if (inputRef.current.value.toLowerCase() == "dev") {
      let id = "dev";
      props.onValidated(id);
    }
    // Check for test code. We do this before checking for a delegate code so it works offline
    else if (inputRef.current.value.toLowerCase() == "test") {
      let id = "demo_" + new Date().toLocaleTimeString();
      props.onValidated(id);
    }
    // check if we received delegate list
    else if (delegates == null) {
      const error = <p id={"error"}>Please check your connection!</p>;
      setError(error);
      return;
    } else {
      console.log(delegates);
      const id = inputRef.current.value.toLowerCase();
      console.log(id);

      let delegate = delegates[inputRef.current.value.toLowerCase()];

      if (delegate == null) {
        const error = <p id={"error"}>Invalid voucher code!</p>;
        setError(error);
      } else if (delegate) {
        const error = <p id={"error"}>Voucher code used!</p>;
        setError(error);
      } else if (delegate == false) {
        let id = inputRef.current.value;
        props.onValidated(id);
      }
    }
  }

  function getDelgates() {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.responseType = "json";

      request.onerror = (err) => {
        reject(err);
      };

      request.onreadystatechange = () => {
        if (request.readyState == 4 && request.status == 200) {
          resolve(request.response);
        }
      };

      request.open(
        "GET",
        process.env.REACT_APP_DELEGATE_ENDPOINT + template.eventName
      );
      request.send();
    });
  }

  if (window.innerWidth <= 768) {
    return renderMobile();
  } else return renderDesktop();
}
