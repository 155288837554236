import React, { useState } from 'react';
import Splash from './components/Splash';
import template from './template.json'
import Designer from './components/Designer';
import ShippingInfo from './components/ShippingInfo';
import '../src/components/styles/App.css';
import '../src/components/styles/Thanks.css';

export default function App (props){

require('dotenv').config()

const [showThanks, setShowThanks] = useState(false);
const [delegate, setDelegate] = useState(null);
const [loggedIn, setLoggedIn] = useState(false);
const [submittedDesign, setSubmittedDesign] = useState(false);
const [design, setDesign] = useState(null);

const designer = 
  <Designer
    design={design}
    template={template}
    delegate={delegate}
    onSubmit={(e) => {setSubmittedDesign(true); setDesign(e)}}
  />

const thanks =
        <div className={"thanksScreen"}>
            {template.style.thanks.logo ? <img id={"logo"} src={template.style.thanks.logo}/> : null }
            <p id={"message"}>Thank You!</p>
            <p id={"calltoaction"}>We have received your design and it has been sent to our fulfilment team. <br></br>
            </p>
            
              {template.style.thanks.url ? <form action={template.style.thanks.url}>
              <input id={"ctaButton"} type={"submit"} value={"Click Here"}/>
            </form> : null}
            </div>

  const splash = Splash({
    onValidated: (e) => {
      setLoggedIn(true);
      setDelegate(e);
    }
  });

  const submission = 
    <ShippingInfo
      design={design}
      template={template}
      backClick={() => setSubmittedDesign(false)} 
      delegate={delegate}
      onSubmission={(address, design) => {
        uploadDesign(address, design);
        deactivateDelgate();
        setShowThanks(true);
      }}
    />

  function uploadDesign(address, design){

    let request = new XMLHttpRequest();
    
    request.onreadystatechange = () => {
        if(request.readyState == 4 && request.status == 200){
        }
    }

    const data = {
        delegate:{
            id:delegate,
            eventName:template.eventName,
        },
        design:design,
        address:address
    }

    request.open("POST", process.env.REACT_APP_DESIGN_ENDPOINT);
    request.setRequestHeader("Content-Type", "application/json")
    request.send(JSON.stringify(data, null, '\t'));
}

  async function deactivateDelgate(){

    if(!delegate) return;

    if(delegate.toLowerCase() == 'test' || delegate.toLowerCase() == 'dev') return;

    let delegates = await getDelgates();

    delegates[delegate] = true;

    let request = new XMLHttpRequest();
    
    request.onreadystatechange = () => {
      if(request.status == 200){
      }
  }

    // only deactivate if not test account
    request.open("POST", process.env.REACT_APP_DELEGATE_ENDPOINT + template.eventName);
    request.setRequestHeader("Content-Type", "application/json");
    request.send(JSON.stringify(delegates));
  }

  function getDelgates(){
    return new Promise((resolve, reject) => {
        let request = new XMLHttpRequest();
        request.responseType ="json"

        request.onerror = (err) => {
            reject(err);
        }

        request.onreadystatechange = () => {
            if(request.readyState == 4 && request.status == 200){
                resolve(request.response);
            }
        }

        request.open("GET", process.env.REACT_APP_DELEGATE_ENDPOINT + template.eventName);
        request.send();
    })
}

  // Rendering

  if(showThanks){
    return thanks;
  }
  else if(!loggedIn){
    return splash;
  }
  else if(loggedIn && !submittedDesign){
    return designer;
  }
  else return submission;

}