import React, { useEffect, useState } from 'react';
import DesignCanvas from './DesignCanvas';
import Toolbar from './Toolbar';
import './styles/Designer.css';
import template from '../template.json'
import * as PIXI from 'pixi.js'

export default function Designer(props){
    
    
    useEffect(() => {
        document.body.style.backgroundImage = "none";

        if(props.design){
            setDesign(props.design);
        }
    }, [])


    const defaultTextPos = {x:template.tools.text.x + template.paperWidth / 2, y:template.tools.text.y + template.paperHeight / 2};

    const [design, setDesign] = useState({
        art:{url:null, layout:template.tools.art.defaultLayout},
        base:null,
        photo:{url:null, position:{x:0, y:0}, rotation:0, scale:1},
        text:{text:null, color:"#000000", position:defaultTextPos, fontSize:template.tools.text.fontSize * template.canvas.scale}
    });

    const toolbar = <Toolbar
                    onTextChange = {onTextChange}
                    onTextColorChange = {onTextColorChange}
                    textColor = {design.text.color}
                    text = {design.text.text}
                    baseChange={baseChange}
                    artChange={artChange}
                    photoScale={design.photo.scale}
                    photoSizeChange={photoSizeChange}
                    layoutChange={layoutChange}
                    onSubmit={onSubmit}
                    photoPicked={photoPicked}
                    clearPhotoClick={clearPhoto}
                    rotationChange = {rotationChange}
                />
    
    function rotationChange(){
        let photo = design.photo;
        photo.rotation += 45;   
        setDesign((prevDesign) => ({...prevDesign, photo:photo}));
    }

    function clearPhoto(){
        setDesign((prevDesign) => ({...prevDesign, photo:{url:null, position:{x:0,y:0}}, rotation:0, scale:1}));
    }

    function photoPicked(url){
        setDesign((prevDesign) => ({...prevDesign, photo:{url:url, position:{x:template.paperWidth/2, y:template.paperHeight/2}, rotation:0, scale:1}}));
    }

    function onSubmit(){  
        props.onSubmit(design);
    }
    
    const designCanvas = <DesignCanvas className={"design-canvas"}
                design={design}
                template={template}
                photoPositionChange ={photoPositionChange}
                textPositionChange = {textPositionChange}
            />
    
    function photoPositionChange(position){
        setDesign((prevDesign) => ({...prevDesign, photo:{url:design.photo.url, position:position, rotation:design.photo.rotation, scale:design.photo.scale}}));
    }
    
    function textPositionChange(position){
        let text = design.text;
        text.position = position;
        setDesign((prevDesign) => ({...prevDesign, text:text}));
    }

    function onTextChange(e){
        let text = design.text;
        let value = "";

        if(e && e.target && e.target.value != undefined){
            value = e.target.value;
        }

        
        const maxWidth = template.tools.text.maxWidth  * template.canvas.scale;
        const defaultFontSize = template.tools.text.fontSize * template.canvas.scale;

        if(value != "" && value != null && value != undefined){
            let style = new PIXI.TextStyle({
                align: template.tools.text.align,
                fontFamily: template.tools.text.font,
                fontSize: text.fontSize,
                fontWeight: template.tools.text.fontWeight,
                fill: text.color,
                wordWrap: false,
                breakWords:false,
                stroke:'#000000',
                strokeThickness:0,
            })
    
            // Resize font to fit within max text width limit
            let textWidth = PIXI.TextMetrics.measureText(value, style).width;
            //   console.log("Text: ", text.text);
            //   console.log("Width: ", textWidth);
              
            if(textWidth >= maxWidth){
                while(textWidth >= maxWidth){
                    text.fontSize -= 1;
                    style.fontSize = text.fontSize;
                    textWidth = PIXI.TextMetrics.measureText(value, style).width;
                }
            }
            else if(textWidth < maxWidth && text.fontSize < defaultFontSize)
            {
                while(textWidth < maxWidth && text.fontSize < defaultFontSize){
                    text.fontSize += 1;
                    style.fontSize = text.fontSize;
                    textWidth = PIXI.TextMetrics.measureText(value, style).width;
                }
            }
        }
        else{
            text.fontSize = template.tools.text.fontSize * template.canvas.scale;
        }

        text.text = value;

        setDesign((prevDesign) => ({...prevDesign, text:text}));
    }
    
    function onTextColorChange(newColor){
        let newText = design.text;
        newText.color = newColor;
        setDesign((prevDesign) => ({...prevDesign, text:newText}));
    }
    
    function photoSizeChange(e){
        let photo = design.photo;
        photo.scale += e;
        photo.scale =  photo.scale.clamp(.25, 6);
        setDesign((prevDesign) => ({...prevDesign, photo:photo}));
    }
    
    function baseChange(newBase){
        setDesign((prevDesign) => ({...prevDesign, base:newBase}));
    }
    
    function artChange (e){
        let art = design.art;
        art.url = e;
        setDesign((prevDesign) => ({...prevDesign, art:art}));
    }

    function layoutChange(e){
        let art = design.art;
        art.layout = e;
        setDesign((prevDesign) => ({...prevDesign, art:art}));
    }

    const page1 = <div className={"designer"}>

            <div className={"canvas-div"}>
                {designCanvas}
            </div>
            <div className={"tools-div"}>
                {toolbar}
            </div>

        </div> 

        return page1;
}