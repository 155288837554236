import React from 'react';
import {PixiComponent, Stage, Sprite, Text, TilingSprite, AppProvider, AppConsumer, useApp, Container } from '@inlet/react-pixi'
import * as PIXI from 'pixi.js'
import template from '..//..//src/template.json'
import useForceUpdate from 'use-force-update';

const Rectangle = PixiComponent('Rectangle', {

    create: props => new PIXI.Graphics(),
    applyProps: (instance, _, props) => {
      const { x, y, width, height, fill } = props;
      instance.clear();
      instance.beginFill(fill);
      instance.drawRect(x, y, width, height);
      instance.endFill();
    },
  });

  let isDraggingPhoto = false;
  let isDraggingText = false;
  let isDraggingArt = false;
  let offset = new PIXI.Point(0,0);
  let loader = new PIXI.Loader();
  let t = null;
  let loading = false;
  let baseName = null;
  let fontSize = template.tools.text.fontSize * template.canvas.scale;

export default function DesignCanvas (props){
    
    const forceUpdate = useForceUpdate();

    const refresh = React.useCallback(() => {
        forceUpdate();
      }, [forceUpdate]);

    function renderText (){

        let text = props.design.text; 

        if(text.text == null) return null;

        let style = new PIXI.TextStyle({
            align: template.tools.text.align,
            fontFamily: template.tools.text.font,
            fontSize: text.fontSize,
            fontWeight: template.tools.text.fontWeight,
            fill: text.color,
            wordWrap: false,
            breakWords:false,
            stroke:'#000000',
            strokeThickness:0,
        })
    
        const textX = (template.design.x * template.canvas.scale) + ((template.design.width * template.canvas.scale) * template.tools.text.x);
        const textY = (template.design.y * template.canvas.scale) + ((template.design.height * template.canvas.scale) * template.tools.text.y);

        return <Text 
            text={text.text}
            interactive={false}
            pointermove={pointerMoveText}
            pointerdown={pointerDownText}
            pointerup={pointerUpText}
            pointerupoutside={pointerUpText} 
            x = {textX}
            y = {textY}
            anchor={.5,.5} pivot ={.5,.5}
            style={style}
        />
    }

    function renderPhoto(){
        if(!props.design.photo.url) return null;
        return <Sprite
                 name={"photo"}
                 image={props.design.photo.url}
                 pivot={.5,.5}
                 anchor={.5,.5}
                 x = {props.design.photo.position.x}
                 y = {props.design.photo.position.y}
                 angle = {props.design.photo.rotation}
                 scale={props.design.photo.scale}
                 interactive={true}
                 pointerdown={pointerDownPhoto}
                 pointerup={pointerUpPhoto}
                 pointerupoutside={pointerUpPhoto}
                 pointermove={pointerMovePhoto}
            />
    }

    function pointerDownPhoto(e){
        if(isDraggingText || isDraggingArt) return;
        isDraggingPhoto = true;
        let clickpos = e.data.getLocalPosition(this.parent);
        let spritePos = e.target.position;
        offset = new PIXI.Point(spritePos.x - clickpos.x, spritePos.y - clickpos.y);
    }

    function pointerDownText(e){
        if(isDraggingPhoto || isDraggingArt) return;
        isDraggingText = true;
        let clickpos = e.data.getLocalPosition(this.parent);
        let spritePos = e.target.position;
        offset = new PIXI.Point(spritePos.x - clickpos.x, spritePos.y - clickpos.y);
    }

    function pointerDownArt(e){
        if(isDraggingPhoto || isDraggingText) return;

        isDraggingText = true;
        let clickpos = e.data.getLocalPosition(this.parent);
        let spritePos = e.target.position;
        offset = new PIXI.Point(spritePos.x - clickpos.x, spritePos.y - clickpos.y);
    }

    function pointerUpPhoto(e){
        isDraggingPhoto = false;
    }
    function pointerUpText(e){
        isDraggingText = false;
    }
    
    function pointerUpArt(e){
        isDraggingArt = false;
    }

    function pointerMovePhoto(e){

        if(isDraggingPhoto){
            
            let pos = e.data.getLocalPosition(this.parent);        
            
            let newpos =
            {
                x:pos.x + offset.x,
                y:pos.y + offset.y
            }

            props.photoPositionChange({x:newpos.x, y:newpos.y});
        }
    }

    function pointerMoveText(e){

        if(isDraggingText){
            
            let pos = e.data.getLocalPosition(this.parent);        
            
            let newpos =
            {
                x:pos.x + offset.x,
                y:pos.y + offset.y
            }

            props.textPositionChange({x:newpos.x, y:newpos.y});
        }
    }

    function pointerMoveArt(e){

        if(isDraggingArt){
            
            window.alert("Dragging art");

            let pos = e.data.getLocalPosition(this.parent);        
            
            let newpos =
            {
                x:pos.x + offset.x,
                y:pos.y + offset.y
            }

            this.x = pos.x;
            this.y = pos.y;
        }
    }


    function renderBase(){
        if(!props.design.base || props.design.base == undefined || props.design.base == "") return null;
        
        // This is a complete hack! We do this to wait for texture load to compare width against height
        if(!loading && props.design.base != baseName){
            loader.add('base', props.design.base);
            baseName = props.design.base;
            loading = true;
            
            loader.load((loader, resources) => {
                t = resources.base.texture;
                loader.reset();
                refresh();
                baseName = props.design.base;
                loading = false;
            });
        }

        if(t) {

            // We only draw base where the design area is defined.
            // This means we can have a larger canvas so we see the merch that we are designing
            
            const x = (template.design.region.x * template.canvas.scale);
            const y = (template.design.region.y * template.canvas.scale);
            const w = (template.design.region.width * template.canvas.scale);
            const h = (template.design.region.height * template.canvas.scale); 

            if(t.width != t.height) return <Sprite x={x} y={y} width={w} height={h} texture={t}/>;
            else return <TilingSprite tileScale= {template.canvas.scale} width={w} height={h} x={x} y = {y} texture={t}/>;
        }
        else return null;
    }

    function renderArt(){
        
        let art = props.design.art;
        
        if(!art.url) return null;

        let sprites = [];

        let seedrandom = require('seedrandom');
        seedrandom(template.tools.seed, { global: true });
        
        if(art.layout === "big"){
            const size = 512 * template.tools.art.bigSize * template.canvas.scale;            
            const x = (template.design.region.x * template.canvas.scale) + ((template.design.region.width * template.canvas.scale) * template.tools.art.x);
            const y = (template.design.region.y * template.canvas.scale) + ((template.design.region.height * template.canvas.scale) * template.tools.art.y);  

            sprites.push(
            <Sprite 
                id={0}
                rotation={0}
                image={art.url}
                width={size} 
                height={size} 
                anchor = {.5,.5}
                pivot={.5,.5}
                x={x}
                y={y}
                interactive={false}
                pointerup={pointerUpArt}
                pointermove={pointerMoveArt}
                pointerupoutside={pointerUpArt}/>)
        }
        else if(art.layout === "tiled"){

            const size = 512 * template.tools.art.tileSize * template.canvas.scale;
            const w = (template.design.region.width * template.canvas.scale);
            const h = (template.design.region.height * template.canvas.scale); 

            const totalHeight = (size + 10) * (h / size);
            const totalWidth = (size + 10) * (w / size);
            const hDiff = (template.design.region.width / totalWidth);
            const vDiff = (template.design.region.height / totalHeight);

            const xOffset = (template.design.region.x * template.canvas.scale) + hDiff + (size /2 ) + 10;
            const yOffset = (template.design.region.y * template.canvas.scale) + vDiff;

            for(let x = 0; x < Math.floor(w / size) -2; x ++){

                for(let y = 0; y < Math.floor(h / size); y ++){
                    let xpos = x * size + (x * 10) + xOffset;
                    let ypos = y * size + (y * 10) + yOffset;
                    sprites.push(<Sprite id={x+y} rotation={0} image={art.url} pivot={.5,.5} anchor={.5, .5} width={size} height={size} x={xpos} y ={ypos + 30}/>)
                }
            }
        }
        else if(art.layout === "rotated"){
            
            const size = 512 * template.tools.art.tileSize * template.canvas.scale;
            const w = (template.design.region.width * template.canvas.scale);
            const h = (template.design.region.height * template.canvas.scale); 

            const totalHeight = (size + 10) * (h / size);
            const totalWidth = (size + 10) * (w / size);
            const hDiff = (template.design.region.width / totalWidth);
            const vDiff = (template.design.region.height / totalHeight);

            const xOffset = (template.design.region.x * template.canvas.scale) + hDiff + (size /2 ) + 10;
            const yOffset = (template.design.region.y * template.canvas.scale) + vDiff;
   

            for(let x = 0; x < Math.floor(w / size) -2; x ++){

                for(let y = 0; y < Math.floor(h / size); y ++){
                    let xpos = x * size + (x * 10) + xOffset;
                    let ypos = y * size + (y * 10 + yOffset);
                    let angle = getRand(0, 360);
                    sprites.push(<Sprite id={x+y} rotation={angle} image={art.url} pivot={.5,.5} anchor={.5, .5} width={size} height={size} x={xpos} y ={ypos + 30}/>)
                }
            }
        }
        else if(art.layout === "scaled"){
            const size = 512 * template.tools.art.tileSize * template.canvas.scale;
            const w = (template.design.region.width * template.canvas.scale);
            const h = (template.design.region.height * template.canvas.scale); 

            const totalHeight = (size + 10) * (h / size);
            const totalWidth = (size + 10) * (w / size);
            const hDiff = (template.design.region.width / totalWidth);
            const vDiff = (template.design.region.height / totalHeight);

            const xOffset = (template.design.region.x * template.canvas.scale) + hDiff + (size /2 ) + 10;
            const yOffset = (template.design.region.y * template.canvas.scale) + vDiff + (size /2 );

            for(let x = 0; x < Math.floor(w / size) -2; x ++){

                for(let y = 0; y < Math.floor(h / size); y ++){
                    
                    let xpos = x * size + (x * 10) + xOffset;
                    let ypos = y * size + (y * 10) + yOffset;
                    let angle = getRand(0, 360);
                    let scale = getRand(.5, 1);
                    sprites.push(<Sprite id={x+y} pivot={0.5,0.5} anchor={0.5, 0.5} image={art.url} rotation={angle} width ={size * scale} height={size * scale} x={xpos} y ={ypos}/>)
                }
            }
        }
        
        return sprites;
    }

    function renderLogo () {
        const logo = template.design.logo;
        const scale = template.canvas.scale;
        const x = (template.design.x * scale) + ((template.design.width * scale) * logo.x);
        const y = (template.design.y * scale) + ((template.design.height * scale) * logo.y);

        return logo ? <Sprite 
                    image={logo.url}
                    x={x}
                    y={y}
                    width={logo.width * scale}
                    height={logo.height * scale} 
                />
        : null
    }

    return(
    <Stage className={"stage"} width={template.canvas.width} height = {template.canvas.height} options={{transparent:true}}>

        {template.canvas.background ? <Sprite image = {template.canvas.background} width={template.canvas.width}/> : null}
        {renderBase()}
        {template.canvas.photo ? renderPhoto() : null}
        {renderArt()}
        {template.canvas.mask ? <Sprite image = {template.canvas.mask}/> : null}
        {template.canvas.overlay ?  <Sprite image = {template.canvas.overlay}/> : null}

        {template.tools.text ? renderText() : null}
        {template.design.logo ? renderLogo() : null} 
        {template.canvas.foreground ?  <Sprite image = {template.canvas.foreground}/> : null}

        {false ? <Rectangle 
                width={template.design.width * template.canvas.scale} 
                height={template.design.height * template.canvas.scale} 
                fill = {0xff0000}
                x = {template.design.x * template.canvas.scale}
                y = {template.design.y * template.canvas.scale}
        /> : null}

     </Stage>)

}

function getRand(min, max) {
    return Math.random() * (max - min) + min;
  }

