import React, { useEffect, useState } from'react';
import './styles/Toolbar.css';
import template from '../template.json';
import EXIF from 'exif-js'

let changingScale = false;

export default function Toolbar (props){
    const photoInput = React.createRef();
    const textInput = React.createRef();
    const [baseImages, setBaseImages] = useState([]);
    const [artImages, setArtImages] = useState([]);
    const [photoIsPicked, setPhotoIsPicked] = useState(false);
    const [toolMode, setToolmode] = useState('base');

    function onTextColorChange (e){
        props.onTextColorChange(e.target.value);
    }

    function layoutClick(e){
        props.layoutChange(e.target.id);
    }
    
    function baseIconClick(e){
        props.baseChange(e.target.id);
    }    

    function clearBase(e){
        props.baseChange(null);
    }

    function clearArt(e){
        props.artChange(null);
    }

    function artIconClick(e){
        props.artChange(e.target.id);
    }

    async function photoPicked(e){
        const data = e.target.files[0];
        const url = await getDataUrlFromBLob(e.target.files[0]);
        props.photoPicked(url);
        setPhotoIsPicked(true);
    }

function getDataUrlFromBLob(blob){
    if(!blob) return null;

    return new Promise((resolve, reject) =>{
        if(FileReader){
            let fr = new FileReader();
            fr.onload = () => resolve(fr.result);
            fr.onerror = reject;
            fr.readAsDataURL(blob);
        }
    })
}

function getRotation(data){

    if(!data) return 0;
    
    EXIF.getData(data, function () {
 
        let orientation = EXIF.getTag(data, "Orientation");
        
        if (orientation == undefined) orientation = 0;

        let rotation = 0;
    
        if(orientation == 3){
            rotation = 180;
        }
    
        if(orientation == 6){
            rotation = 90;
        }
    
        if(orientation == 8){
            rotation = -90;
        }

        return rotation;
    });
}

    useEffect(() =>{

        let bases = [];
        let stickers = [];

        bases.push(
            <button className={"asset-icon"} style={{background:'url(images/ui/delete.jpg)'}} onClick= {clearBase}/>
        );

        stickers.push(
            <button className={"asset-icon"} style={{background:'url(images/ui/delete.jpg)'}} onClick= {clearArt}/>
        );

        // map base image names to dir names
        for(let i=1; i <= template.tools.base.count; i ++){
            var thumbURL = "images/base/thumbs/" + i.toString() +".jpg";
            var highResURL = "images/base/quarter/" + i.toString() +".jpg";
            bases.push(<button className={"asset-icon"} style={{background:'url(' + thumbURL + ')'}} id={highResURL} onClick= {baseIconClick}/>);
        }

        for(let i=1; i <=template.tools.art.count; i ++){
            var thumbURL = "images/art/thumbs/" + i.toString() +".jpg";
            var highResURL = "images/art/" + i.toString() +".png";            
            stickers.push(<button className={"asset-icon"} style={{background:'url(' + thumbURL + ')'}} id={highResURL} onClick= {artIconClick}/>);
        }

        setBaseImages(bases);
        setArtImages(stickers);
    }, []);

    let toolicons = null;

    let clickInput = () => 
    {
        if(!photoIsPicked){
            photoInput.current.click();
        }
    }

    if(toolMode == 'base'){
        toolicons = 
        <>
            {baseImages}
        </>;
    }

    
    if( toolMode == 'camera'){
        toolicons = <>
                    <button className = {"asset-icon"} style={{background:"url(images/ui/delete.png)",backgroundSize:"cover"}} onClick = {() => {props.clearPhotoClick(); setPhotoIsPicked(false)}}/>
                    <input className={"photo-input"} type={"file"} accept={".jpg"} onChange={photoPicked} style={{display:"none"}} ref ={photoInput}/>
                    <button className = {"asset-icon"} onClick = {() => {props.photoSizeChange(-.25)}} style={{background:"url(images/ui/zoom-out.png)",backgroundSize:"cover"}}></button>
                    <button className = {"asset-icon"} onClick = {clickInput} style={{background:"url(images/ui/shutter.png)",backgroundSize:"cover"}}></button>
                    <button className = {"asset-icon"} onClick = {() => {props.photoSizeChange(.25)}} style={{background:"url(images/ui/zoom-in.png)",backgroundSize:"cover"}}></button>
                    <button className = {"asset-icon"} style={{background:"url(images/ui/rotate.png)",backgroundSize:"cover"}} onClick = {props.rotationChange}/>
                </> 
        }

    if(toolMode == 'art'){
        toolicons = <>
                {artImages}
        </>
    }
    
    if(toolMode == 'layout'){
        toolicons = <>
            {template.tools.art.layouts.includes("big") ? <button className = {"asset-icon"} id={"big"} onClick={layoutClick} style={{background:"url(images/ui/centre-large-icon.jpg)"}}/> : null}
            {template.tools.art.layouts.includes("tiled") ? <button className = {"asset-icon"} id={"tiled"} onClick={ layoutClick } style={{background:"url(images/ui/tiled-icon.jpg)"}} />  : null}
            {template.tools.art.layouts.includes("rotated") ? <button className = {"asset-icon"} id={"rotated"} onClick={ layoutClick} style={{background:"url(images/ui/rotated-tiled-icon.jpg)"}} />  : null}
            {template.tools.art.layouts.includes("scaled") ? <button className = {"asset-icon"} id={"scaled"} onClick={ layoutClick} style={{background:"url(images/ui/scaled-rotated-icon.jpg)"}}/>  : null}
        </>
    }
    
    if(toolMode == 'text'){
        toolicons = <>
            <button className = {"asset-icon"} style={{background:"url(images/ui/delete.png)",backgroundSize:"cover"}} onClick = {() => {textInput.current.value = ""; props.onTextChange(null)}}/>
            <input id={"textinput"} type = {"text"} placeholder={"enter text here"} ref ={textInput} value={props.text} maxLength={template.tools.text.maxChars} onChange={props.onTextChange}/>
            <input id={"colorpicker"} type={"color"} value={props.textColor} onChange={onTextColorChange}/>
        </>
    }

    // <button id={"tool"} style={{background:"url(images/ui/photo-icon.jpg)"} } onClick={() => setToolmode('camera')}></button>


    return <div className={"toolbar"}>
            {toolMode == 'base' || toolMode == 'art' ? 
            <>
                <div id={"icongrid"}>
                {toolicons}
                </div>
            </> :
            <>
                <div id={"toolgrid"}>
                {toolicons}
            </div>

            </>
        
            }
            <div id={"tools"}>
                {template.tools.base ? <button id={"tool"} style={{background:'url(images/ui/canvas-icon.jpg)'} } onClick={() => setToolmode('base')} ></button> : null}
                {template.tools.art ? <button id={"tool"} style={{background:'url(images/ui/sticker-icon.jpg)'} } onClick= {() => setToolmode('art')}></button> : null}
                {template.tools.art.layouts.length != 0 ? <button id={"tool"} style={{background:'url(images/ui/layout-icon.jpg)'}} onClick={() => setToolmode('layout')}></button> : null}
                {template.tools.text ? <button id={"tool"} style={{background:'url(images/ui/text-icon.jpg)'}} onClick={() => setToolmode('text')} ></button> : null}
            </div>

            <div id="done">
                <button onClick={props.onSubmit}>Order Now</button>
            </div>
        </div>
}

Number.prototype.clamp = function(min, max) {
    return Math.min(Math.max(this, min), max);
  };