import React, { useState, useEffect } from 'react';
import countries from '../countries.json'
import './styles/ShippingInfo.css'
import emailjs from 'emailjs-com';

let formData = {};

export default function ShippingInfo(props){
    
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {

        let opacity = 1;
        if(props.template.style.splash.opacity) opacity = props.template.style.splash.opacity;

        document.body.style.backgroundImage = "linear-gradient(rgba(0, 0, 0," + opacity + "), rgba(0, 0, 0," + opacity + ")), url('images/ui/bg.jpg')"
    }, [])

    let opacity = 1;
    if(submitting) opacity = .7;

    function fieldChange(e){
        let name = e.target.name;
        formData[name] = e.target.value;
    }
    
    async function submit(e){
        e.preventDefault();
        setSubmitting(true);
        
        if(props.delegate.toLowerCase() === 'dev'){
            props.onSubmission(formData, props.design); 
        }
        else{
            await emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE, props.template.email.templateName, e.target, process.env.REACT_APP_EMAIL_USER).then((result) => {
                props.onSubmission(formData, props.design);
            }, (error) => {
                window.alert("Please check your connection!");
            });
        }
    }

    let form = <>
            <div id={"container"} opacity={opacity}>
            <form onSubmit={submit} disabled={submitting}>
            <input name={"streetAddress"} type={"hidden"} name={"product"} value={props.template.name} onChange={fieldChange}/><br></br>
            <input name={"orderNo"} type={"hidden"} value={props.delegate}/><br></br>
            <img id={"logo"} src={"images/ui/logo.png"}></img>
            <h1>Shipping</h1>
            <p>Please enter your shipping details.</p> <br></br>
            {props.template.style.shippingInfo.sizes != null ?  
            <>
                <label>Size</label><br></br>
                <select name = "size" onChange={fieldChange}>
                    {props.template.style.shippingInfo.sizes.map((val) => {
                        return <option value={val}>{val}</option>
                    })}
                </select><br></br>
            </> : null}
            <label>First Name</label><br></br>
            <input name="firstName" type={"text"} required={true} onChange={fieldChange}/><br></br>
            <label>Last Name</label><br></br>
            <input name={"lastName"} type={"text"} required={true} onChange={fieldChange}/><br></br>
            
            <label>Country</label><br></br>
            <select name ="country" onChange={fieldChange}>
                {countries.map((country) => {
                    return <option value={country}>{country}</option>
                })}

            </select><br></br>

            <label>House number and street name</label><br></br>

            <input name={"streetAddress"} type={"text"} required={true} onChange={fieldChange}/><br></br>
            <label>Town / City</label><br></br>

            <input name={"city"} type={"text"} required={true} onChange={fieldChange}/><br></br>
            <label>County / State</label><br></br>

            <input name={"county"} type={"text"} required={true} onChange={fieldChange}/><br></br>
            <label>Postal / Zip code</label><br></br>

            <input name={"postcode"} type={"text"} onChange={fieldChange}/><br></br>

            <label>Email Address</label><br></br>

            <input name={"email"} type={"email"} required onChange={fieldChange}/><br></br>
            
            {
                props.template.style.shippingInfo.collectTel ? <> 
                    <label required>Phone Number</label><br></br>
                    <input name={"phone"} type={"tel"} required onChange={fieldChange}/><br></br>
               </> : null
            }
            
            <span>
                <br></br>
                <button id={"btnBack"} onClick={props.backClick}>Back</button>
                <button id={"btnSubmit"} disabled={submitting} type={"submit"} > Finished</button>
            </span>
    </form>
    </div>
    </>

    if(submitting) return <><div className={"loadingDiv"}>{Loader("Submitting Design...")}</div></>;
    else return  form;
}

const Loader = ({ message }) => {
  
    return (
      <div className="loader-container">
        <div className="loader" />
        <span className="loading-text">{message ? message : "Loading..."}</span>
      </div>
    )
  }